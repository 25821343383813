import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Layout} from "../components/common"
import {Button} from "react-bootstrap"
import {Link} from "gatsby"

let videoBG = require('../images/page-images/construction-project-management-aerial-photography.jpg')

class AboutUs extends Component {
    render() {
        return (
            <Layout>

                <div className="container">
                    <h1>
                        About Us
                    </h1>
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                We are a team with over 10 years of combined drone and photography experience. At our core we are
                                engineers and problem solvers and we're passionate about using drones and unmanned aerial systems to solve
                                complex problems.
                            </p>
                            <h3>Our Promise:</h3>
                            <ul>
                                <li><strong><strong>We are responsive</strong></strong> – get a response quickly
                                </li>
                                <li><strong><strong>We are reliable</strong></strong> – on schedule and delivered as
                                    promised.
                                </li>
                                <li><strong><strong>We are efficient</strong></strong> – fast turnaround times to
                                    help you meet your deadlines.
                                </li>
                                <li><strong><strong>We guarantee your satisfaction </strong></strong>– going the
                                    extra mile
                                </li>
                                <li><strong><strong>We are capable</strong></strong> – all pilots are specialized
                                    and trained on the equipment
                                </li>
                                <li><strong><strong>We are certified</strong></strong> – all pilots are FAA UAS Part
                                    107 Certified
                                </li>
                                <li><strong><strong>We are insured </strong></strong>– all pilots carry minimum $1M
                                    insurance
                                </li>
                                <li><strong><strong>We are safe</strong></strong> – We use strict safety protocols
                                    and follow FAA and local regs
                                </li>
                                <li><strong><strong>We are Pros</strong></strong> – We use professional-grade
                                    hardware and software
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
            </Layout>
        )
    }
}

AboutUs.propTypes = {}

export default AboutUs
